 import { provinces_and_cities } from "../services/list"
 
 export const List1 = ({data, curInput, setData, allData}) => {
  const id = data.name.toLowerCase()
  const i = allData.indexOf(data) 
  const handleChange = (event) => {
    const newData = [...allData]
    newData[i].selected =  event.target.checked
    setData(newData)
  }

  return(
    <li className={id.includes(curInput) ? 'flex items-center' : 'hidden'}>
      <input id={id} type="checkbox" defaultChecked={data.selected} value=""
        className="w-2.5 h-2.5 bg-gray-100 border-gray-300 rounded accent-dark-orange dark:bg-gray-600 dark:border-gray-500 " 
        onChange= {handleChange}/>
      <label htmlFor={id} className="ml-1 text-[0.7rem] font-medium text-gray-900 ">
        {data.name}
      </label>
    </li>
  )
}

export const List2 = ({data, curInput, setData, allData, provinces}) => {
  const id = data.name.toLowerCase()
  const i = allData.indexOf(data) 
  const handleChange = (event) => {
    const newData = [...allData]
    newData[i].selected =  event.target.checked
    setData(newData)
  }
  
  return(
    <li className={provinces.filter((d) => d.selected).length>0 ?(provinces.filter((d) => d.selected).map((d)=>d.name).includes(provinces_and_cities.filter((city)=> city[1].includes(data.name)).map((d)=>d[0])[0]) ?(id.includes(curInput) ? 'flex items-center' : 'hidden'): 'hidden' ) : (id.includes(curInput) ? 'flex items-center' : 'hidden')}>
      <input id={id} type="checkbox" defaultChecked={data.selected} value=""
        className="w-2.5 h-2.5 bg-gray-100 border-gray-300 rounded active:text-white accent-dark-orange dark:bg-gray-600 dark:border-gray-500" 
        onChange= {handleChange}/>
      <label htmlFor={id} className="ml-1 text-[0.7rem] font-medium text-gray-900 ">
        {data.name}
      </label>
    </li>
  )
}

