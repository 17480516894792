// Importing required hooks and services
import { useEffect, useState } from 'react';
import getAll from '../services/stores';
import { onSuccess } from '../services/functions';
import { cities } from '../services/list';

// Define custom hook for handling filtered stores data and related operations
export const useFilteredStores = (itemsPerPage, city, province, bikeShops, serviceShops, curInput) => {
    // Initialize state variables
    const [filteredStores, setFilteredStores] = useState([]); // Holds the filtered stores data
    const [pageCount, setPageCount] = useState(0); // Holds the total page count based on filtered data
    const [loadDone, setLoadDone] = useState(false); // Holds the state of data loading
    const [ stores, setStores ] = useState([]) // Holds the original stores data
    const [ paginationStart, setPaginationStart] = useState(0); // Holds the start index for pagination
    const [ paginationEnd, setPaginationEnd] = useState(itemsPerPage); // Holds the end index for pagination
    const [page, setPage] = useState(1) // Holds the current page number

    // Effect hook to fetch stores data
    useEffect(() => {
        // Fetch all stores data
        getAll().then(stores => {
            const res = onSuccess(stores); // Process data on success
            setStores(res); // Update stores state with fetched data
            setFilteredStores(res); // Initially, set all stores data to filteredStores
            setPageCount(Math.ceil(res.length / itemsPerPage)); // Calculate page count based on itemsPerPage
            setLoadDone(true); // Indicate data loading is done
        })
    }, [itemsPerPage]); // Dependence on itemsPerPage
    // Effect hook to filter stores data based on provided parameters
    useEffect(() => {
      // Function to filter stores by location and brands
      const filterStoresByLocationAndBrands = () =>{
        // Filtering the selected provinces and cities
        const selectedProvinces = province.filter((data) => data.selected)
        const selectedCities = cities.filter((data) => data.selected)
        // Filtering stores based on keyword, location and store type
        const list = stores.filter((store) => {
          const filterByKeyword = store.brands.toLowerCase().trim().includes(curInput.toLowerCase().trim()) || store.name.toLowerCase().trim().includes(curInput.toLowerCase().trim())
          const filterByLocation = selectedCities.length>0 ?(selectedCities.map((data) => data.name).includes(store.city)):(selectedProvinces.length > 0 ? (selectedProvinces.map((data) => data.name).includes(store.province)) : true)
          const filterByStoreType = ((bikeShops && serviceShops) || (!bikeShops && !serviceShops)) ? true :(bikeShops ? (/TRUE/).test(store.bikeShop) : (/TRUE/).test(store.serviceShop))
          return filterByKeyword && filterByLocation && filterByStoreType
        })
        // If the length of the list is less than pagination start, reset the pagination
        if (list.length<paginationStart){
          setPaginationStart(0)
          setPaginationEnd(itemsPerPage)
          setPage(1)
          setFilteredStores(list.slice(0, itemsPerPage))
          setPageCount(Math.ceil(list.length/itemsPerPage))
        } else{
          setFilteredStores(list.slice(paginationStart, paginationEnd))
          setPageCount(Math.ceil(list.length/itemsPerPage))
      }}

      filterStoresByLocationAndBrands() // Call the filtering function
    }, [stores, paginationStart, paginationEnd, curInput, city, province, bikeShops, serviceShops, itemsPerPage]); // Dependence on several state variables

    // Function to handle page changes
    const goToPage = (event,value) => {
      setPage(value) // Set the current page to the selected page
      setPaginationStart(value*10-10) // Update the start index for pagination based on the selected page
      setPaginationEnd(value*10) // Update the end index for pagination based on the selected page
    }

    // Return the required state variables and function from the hook
    return { filteredStores, pageCount, loadDone, page, goToPage };
};


