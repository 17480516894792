import {HiChevronDown} from 'react-icons/hi'
import { useState } from 'react'

const StoreData = ({store}) => {
  const [show, setShow] = useState(false)
  const bikeStore =  (/TRUE/).test(store.bikeShop)
  const serviceStore = (/TRUE/).test(store.serviceShop)
  return(
    <tr className={store.name}>
      <td className="text-[0.65rem] sm:text-base w-full py-1 sm:py-2 pl-1 pr-1 sm:pl-2 border-b border-gray-200">
        <div className="flex gap-2 sm:gap-4 pb-1">
          <a className="text-dark-orange underline hover:no-underline  hover:text-hover-orange w-1/3 min-[850px]:w-3/12 max-[500px]:w-2/3" href={store.link} rel="noreferrer" target="_blank" >{store.name}</a>
          <div className="min-[850px]:w-5/12 w-1/2 max-[500px]:hidden">{store.address}</div>
          <span className='w-3/12 max-[850px]:hidden'> 
          {bikeStore && serviceStore ? 'Pyörä- ja huoltoliike' :( bikeStore ? 'Pyöräliike' : 'Huoltoliike')}
          </span>
          <button className='w-1/6 text-right flex align-right min-[850px]:w-1/12 text-dark-orange hover:text-hover-orange max-[500px]:w-1/3' onClick={() => setShow(!show)}>
            <div className='max-[850px]:w-1/3 max-[500px]:w-7/12'></div>
            <span className=''>{window.innerWidth<500 ?'Lisätiedot': 'Merkit'}</span>
            <span className='align-baseline flex items-right justify-center pt-0.5 sm:pt-1'><HiChevronDown/></span>
          </button>
        </div>
        <div className={`p-1 rounded-md shadow-lg bg-white border text-[0.6rem] sm:text-sm  ${show ? '' :'hidden'}`}>
          <div className='min-[500px]:hidden border-b w-full border-gray-200'>
            <span>
                {store.address}
            </span>
          </div>
          <div className='flex w-full pt-1.5'>
            <span className='w-2/3'>
              {store.brands}
            </span>
            <span className='pl-2 min-[850px]:hidden text-middle'>
              {bikeStore && serviceStore ? 'Pyörä- ja huoltoliike' :( bikeStore ? 'Pyöräliike' : 'Huoltoliike')}
            </span>
          </div>
        </div> 
      </td>
    </tr>
  )
}

export default StoreData