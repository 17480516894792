// Import necessary dependencies
import { useState } from 'react';
import { cities, provinces as prov } from './services/list';
import StoreData from './components/StoreData';
import Pagination from "@mui/material/Pagination"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Navigation from './components/Navigation';
import { useFilteredStores } from './hooks/index.js';

// Define main component of the app
const  App = () => {
  // Define how many items are displayed per page based on the window width
  const itemsPerPage = window.innerWidth < 640 ? 16 : 10

  // Initialize state for cities, province, current input, and types of shops
  const [city, setCity ] = useState(cities)
  const [province, setProvince ] = useState(prov);
  const [curInput, setCurInput] = useState('')
  const [bikeShops, setBikeShops] = useState(true)
  const [serviceShops, setServiceShops] = useState(false)

  // Create a custom theme for Material UI components
  const theme = createTheme({
    zIndex:{
      modal: 0
    },
    palette:{
      warning:{
        main: '#ff6647'
      }
    }
  })

  // Use custom hook to filter the stores
  const { filteredStores, pageCount, loadDone, page, goToPage } = useFilteredStores(itemsPerPage, city, province, bikeShops, serviceShops, curInput );

  // Return the JSX to render
  return(
      <div className='bg-light-orange h-screen font-light p-1'>
        <div className='bg-white rounded shadow min-h-[67%] p-1'>
          <Navigation setProvinces={setProvince} bikeShops={bikeShops} serviceShops={serviceShops} 
          setBikeShops={setBikeShops} setServiceShops={setServiceShops} provinces={province} 
          cities={city} setCities={setCity} curInput={curInput} setCurInput={setCurInput} />
          {  filteredStores.length !== 0 ?
            <div>
            <div className="">
              <div id="liikkeet" className="">                       
                <table className='table:fixed w-full' id="data_table">
                  <tbody className="" id="list">
                    {filteredStores.map((store,i)=>(
                      <StoreData key={i} store={store}/>
                    ))}
                  </tbody>
                </table>      
              </div>
            </div>
            <ThemeProvider theme={theme} >
              <div className='z-10 flex justify-center align-middle items-center'>
                  <Pagination count={pageCount} page={page} onChange={goToPage} sx={{zIndex: 'modal'}} size={`${window.innerWidth<500 ?'small':'medium'}`}/>
              </div>
            </ThemeProvider>
            </div>
            : <div> 
            {loadDone 
            ? <div>0 Hakutulosta</div>
            :<div className="loader-position"><div className="loader"></div></div>
            } 
          </div>}
        </div>
      </div>
  );
}

export default App;
