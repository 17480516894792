export const onSuccess = (stores) => {
  console.log(stores[0])
  const response = stores.map((store, i)=>{
    return{
      name: store[0] || '', 
      address: store[1] || '',
      link: store[2] || '',
      brands: store[4] || '',
      province: store[5] || '',
      city: store[6] || '',
      serviceShop: store[7] || '',
      bikeShop: store[8] || '',
      index: i,
    }})
  return response     
} 

export const cityOptionsReset = () => {
  const text = 'Kaikki kaupungit'
  const $select = document.querySelector('#cities')
  const $options = Array.from($select.options)
  const optionToSelect = $options.find(item => item.text === text)
  optionToSelect.selected = true
}