const city = ["Akaa","Alajärvi","Alavus","Espoo","Forssa","Haapajärvi","Haapavesi","Hamina","Hanko","Harjavalta","Heinola","Helsinki","Huittinen","Hyvinkää","Hämeenlinna","Iisalmi","Ikaalinen","Ilomantsi","Imatra","Joensuu","Jyväskylä","Jämsä","Järvenpää","Kaarina","Kajaani","Kalajoki","Kangasala","Kankaanpää","Kannus","Karkkila","Kaskinen","Kauhajoki","Kauhava","Kauniainen","Kemi","Kemijärvi","Kerava","Keuruu","Kitee","Kiuruvesi","Kokemäki","Kokkola","Kotka","Kouvola","Kristiinankaupunki","Kuhmo","Kuopio","Kurikka","Kuusamo","Lahti","Laitila","Lappeenranta","Lapua","Lieksa","Lohja","Loimaa","Loviisa","Maarianhamina","Mikkeli","Mänttä-Vilppula","Naantali","Nivala","Nokia","Nurmes","Närpiö","Orimattila","Orivesi","Oulainen","Oulu","Outokumpu","Paimio","Parainen","Parkano","Pieksämäki","Pietarsaari","Pori","Porvoo","Pudasjärvi","Pyhäjärvi","Raahe","Raasepori","Raisio","Rauma","Riihimäki","Rovaniemi","Saarijärvi","Salo","Sastamala","Savonlinna","Seinäjoki","Somero","Suonenjoki","Tampere","Tornio","Turku","Tuusula","Ulvila","Uusikaarlepyy","Uusikaupunki","Vaasa","Valkeakoski","Vantaa","Varkaus","Viitasaari","Virrat","Ylivieska","Ylöjärvi","Ähtäri","Äänekoski"];

export const cities = city.map((data) => ({name: data, selected: false}))

export const provinces_and_cities = [
  ["Ahvenanmaa", [ "Maarianhamina"]], 
  ["Etelä-Karjala", ["Imatra", "Lappeenranta"]],
  ["Etelä-Pohjanmaa", ["Alajärvi", "Alavus", "Kauhajoki", "Kauhava", "Kurikka", "Lapua", "Seinäjoki", "Ähtäri", "Evijärvi"]],
  ["Etelä-Savo", ["Juva" ,"Mikkeli","Kerimäki", "Pieksämäki", "Savonlinna"]],
  ["Kainuu", ["Kajaani", "Kuhmo", "Sotkamo", "Suomussalmi"]],
  ["Kanta-Häme", ["Forssa","Hattula", "Hämeenlinna", "Riihimäki","Loppi"]],
  ["Keski-Pohjanmaa", ["Kannus", "Kokkola"]],
  ["Keski-Suomi", ["Jyväskylä", "Jämsä","Jämsänkoski", "Keuruu","Muurame","Pihtipudas", "Saarijärvi", "Viitasaari", "Äänekoski"]],
  ["Kymenlaakso", ["Hamina", "Kotka", "Kouvola"]],
  ["Lappi",["Kemi", "Kemijärvi", "Ranua", "Rovaniemi", "Tornio", "Saariselkä", "Sodankylä"]],
  ["Päijät-Häme", ["Heinola","Hollola","Lahti", "Orimattila"]],
  ["Pirkanmaa", ["Akaa","Ikaalinen","Kangasala","Mänttä-Vilppula","Nokia","Orivesi","Parkano","Pirkkala","Sastamala","Tampere","Valkeakoski","Virrat","Ylöjärvi"]],
  ["Pohjanmaa", ["Kaskinen","Kristiinankaupunki","Närpiö","Pietarsaari","Uusikaarlepyy","Vaasa","Kokkola"]],
  ["Pohjois-Karjala", ["Joensuu","Ilomantsi","Kitee","Lieksa","Nurmes","Outokumpu"]],
  ["Pohjois-Pohjanmaa", ["Haapajärvi","Haapavesi","Haukipudas", "Kalajoki","Kuusamo","Nivala","Oulainen","Oulu","Pudasjärvi","Pyhäjärvi","Raahe","Ylivieska"]],
  ["Pohjois-Savo", ["Iisalmi","Kiuruvesi","Kuopio","Suonenjoki","Varkaus"]],
  ["Satakunta", ["Eura","Harjavalta","Huittinen","Kankaanpää","Kokemäki","Pori","Rauma","Ulvila"]],
  ["Uusimaa", ["Espoo","Hanko","Helsinki","Hyvinkää","Järvenpää","Karkkila","Kauniainen","Kerava", "Klaukkala","Lohja","Loviisa","Mäntsälä","Porvoo","Raasepori","Tuusula","Vantaa"]],
  ["Varsinais-Suomi", ["Aura", "Kaarina","Laitila","Loimaa","Naantali","Nousiainen","Paimio","Parainen","Raisio","Salo","Somero","Turku","Uusikaupunki", "Lieto"]]
]
export const provinceList = new Map(provinces_and_cities)

const prov = [
"Ahvenanmaa", 
"Etelä-Karjala",
"Etelä-Pohjanmaa",
"Etelä-Savo", 
"Kainuu", 
"Kanta-Häme", 
"Keski-Pohjanmaa", 
"Keski-Suomi", 
"Kymenlaakso", 
"Lappi",
"Päijät-Häme", 
"Pirkanmaa", 
"Pohjanmaa", 
"Pohjois-Karjala", 
"Pohjois-Pohjanmaa", 
"Pohjois-Savo", 
"Satakunta", 
"Uusimaa", 
"Varsinais-Suomi"
]

export const provinces = prov.map((province) => ({name: province, selected:false}))
