// Necessary imports
import { useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import Switch from '@mui/material/Switch';
import {List1, List2} from './List'
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create custom theme for Material UI components
const theme = createTheme({
  palette:{
    warning:{
      main: '#ff6647'
    }
  }
})

// Utility function for applying CSS classes
const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

// Navigation component
export default function Navigation({provinces, setProvinces, setCities, cities, setCurInput, setServiceShops, setBikeShops, serviceShops, bikeShops}) {
  // Local state for controlling the display of the dropdown menu and input values
  const [ canShow, setCanShow] = useState(false)
  const [ provInput, setProvInput] = useState('')
  const [ cityInput, setCityInput] = useState('')

  // Event handlers for toggling bike and service shops switches
  const handleBikeShopChange = () => {
    setBikeShops(!bikeShops)
    if(bikeShops && !serviceShops){
      setServiceShops(true)
    }
  }

  const handleServiceShopChange = () => {
    setServiceShops(!serviceShops)
    if(serviceShops && !bikeShops){
      setBikeShops(true)
    }
  }

  // Component return
  return (
    <Disclosure as="nav" className="">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-10 items-center justify-between">
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

              <input className='rounded-lg p-0.5 sm:p-1 focus:outline-dark-orange text-xs sm:text-sm bg-gray-200' type="text" id="haku" placeholder="Hae merkkiä tai liikettä" onChange={(e)=> setCurInput(e.target.value)}/>
                <Menu as="div" className="relative z-30 ml-3">
                  <div>
                    <Menu.Button className="flex rounded-xl py-1 px-1.5 text-xs sm:text-base border text-white bg-dark-orange hover:bg-hover-orange :" onClick={() => setCanShow(!canShow)}>
                      Suodata
                    </Menu.Button>
                  </div>
                  <Transition
                    show = {canShow}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                  <Menu.Items className="absolute right-0 mt-2 w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <div className={classNames(
                            active ? 'bg-gray-100 ' : 'text-gray-700',
                            'block px-4 py-2 text-xs w-full '
                          )}>
                            <span className='w-full text-left flex align-center'>Maakunnat</span>
                            <div className='pt-1'>
                              <input placeholder='Etsi' type="text" className='bg-gray-100 rounded-md p-0.5 text-[0.7rem] focus:outline-dark-orange' onChange={(e) => setProvInput(e.target.value.toLowerCase().trim())}/>
                              <ul className='space-y-2 pt-1 overflow-y-auto h-24'>
                                {provinces.map((province, i) =>(
                                  <List1 data={province} curInput={provInput} key={i} setData={setProvinces} allData={provinces}/>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1 ">
                      <Menu.Item>
                          {({ active }) => (
                            <div className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-xs w-full '
                            )}>
                              <span className='w-full text-left flex align-center'>Kaupungit</span>
                              <div className='pt-1'>
                                <input placeholder='Etsi' type="text" className='bg-gray-100 rounded-md p-0.5 text-[0.7rem] focus:outline-dark-orange' onChange={(e) => setCityInput(e.target.value.toLowerCase().trim())}/>
                                <ul className='space-y-2 pt-1 overflow-y-auto h-24'>
                                  {cities.map((city, i) =>(
                                    <List2 data={city} curInput={cityInput} key={i} setData={setCities} allData={cities} provinces={provinces}/>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )}
                        </Menu.Item>
                    </div>
                    <div className="py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                      <Menu.Item>
                      {({ active }) => (
                        <div className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-xs w-full '
                        )}>
                          <span className='w-full text-left flex align-center'>Liikkeet</span>
                          <div className='pt-1'>
                            <ThemeProvider theme={theme}>
                              <div className='flex align-baseline items-start pt-0.5'>
                                <Switch size='small' onChange={handleBikeShopChange} checked={bikeShops} color="warning" />
                                <span className={`align-middle pt-1 ${bikeShops ? 'text-dark-orange': ''}`}>Pyöräliikkeet</span>
                              </div>
                              <div className='flex align-baseline items-start pt-0.5'>
                                <Switch size='small' onChange={handleServiceShopChange} checked={serviceShops} color="warning" />
                                <span className={`align-middle pt-1 ${serviceShops ? 'text-dark-orange': ''}`}>Huoltoliikeet</span>
                              </div>
                            </ThemeProvider>
                          </div>
                        </div>
                      )}
                    </Menu.Item>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-xs'
                            )}
                          >
                            <button className='w-full text-left' onClick={() => setCanShow(false)}>
                              Käytä
                            </button>
                          </div>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  )
}
